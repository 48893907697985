.bat-tree {
  /* background-image: url("../../assets/images/BatTree.png"); */
  background-position: top left;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  position: absolute;
  top: 0;
  left: 6%;
  width: 65%;
  z-index: 0;
}

@media (max-width: 1024px) {
  .bat-tree {
    left: 3%;
    width: 80%;
  }
}
@media (max-width: 768px) {
  .bat-tree {
    left: 0%;
    width: 75%;
    display: none;
  }
}
@media (max-width: 425px) {
  .bat-tree {
    width: 65%;
  }
}

@media (max-width: 433px) {
  .bat-tree {
    width: 95%;
    background-size: cover;
  }
}
