.about-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 992px) {
  .about-container {
    margin-top: 3%;
  }
}
