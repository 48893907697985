.bat-hanging {
  background-image: url("../../assets/images/batsHanging.png");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  position: absolute;
  top: 1;
  left: 1%;
  width: 13%;
  z-index: 0;
  transform: scale(0.9);
}
.bg-video {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1; */
}
@media (max-width: 768px) {
  .bat-hanging {
    background-image: none;
  }
}
