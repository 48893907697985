.frame-container {
  border-top: 30px solid black;
  border-left: 10px solid black;
  border-right: 10px solid black;
  border-bottom: 10px solid black;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  position: relative;
  font-family: "PixelFont", sans-serif;
  color: #fff;
  margin-top: 2%;
  background-color: #9090ff;
  height: auto;
}

.xs-small-frame {
  width: 300px;
  height: 150px;
}

.x-small-frame {
  width: 300px;
  height: 350px;
}
.small-frame {
  width: 500px;
  height: 340px;
}

.medium-frame {
  width: 550px;
  height: 490px;
}

.large-frame {
  width: 950px;
  height: 631px;
}

.frame-title {
  position: absolute;
  top: -5%;
}

.frame-title h1 {
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.5rem;
}

.manifesto-frame-title {
  position: absolute;
  top: -4%;
}
.manifesto-frame-title h1 {
  font-size: 1.5rem;
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
}

.close-icon {
  position: absolute;
  cursor: pointer;
}
.close-icon img {
  width: 20px;
}
.close-icon-small {
  top: -5%;
  left: 4.5%;
  z-index: 1000;
}
.close-icon-small img {
  width: 22px;
}
.close-icon-large {
  top: -4.5%;
  left: 4.58%;
}
.close-icon-large img {
  width: 33px;
  height: 27px;
}
.close-icon:hover {
  transform: scale(1.1);
}

.frame-content {
  text-align: center;
  width: 90%;

  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}
.collabs-close {
  top: -7% !important;
}
.collabs-title {
  top: -7% !important;
}
.para {
  line-height: 1;
  text-align: center;
  font-size: 1.2rem;
}

.large-text {
  font-size: 1.3rem;
}

.medium-text {
  font-size: 1.2rem;
}

.small-text {
  font-size: 1rem;
}

.manifesto {
  line-height: 1;
  text-align: center;
  font-size: 1rem;
  padding: 0px 1rem;
}

ul {
  list-style: none;
  padding: 0;
}
ul li {
  padding: 0.5rem;
  font-size: 1.5rem;
}

input[type="text"] {
  width: 80%;
  height: 5px;
  border-radius: 5px;
  border: none;
  padding: 14px 25px;
  background-color: #ffffff;
  font-family: "PixelFont", sans-serif;
  color: grey;
  outline: none;
  box-shadow: none;
  font-size: 16px;
}

input[type="text"]::placeholder {
  color: grey;
}

input[type="text"]:focus {
  border-color: #000;
}

li,
p {
  color: #000;
  letter-spacing: 2px;
}

.input-container {
  width: 100%;
  height: 90px;
  position: relative;
}
.submit-wrapper {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 5px;
}

.input-container p {
  text-align: start;
  padding: 5px;
  display: inline;
}
.success-message {
  color: green;
  padding-bottom: 10px;
  font-size: 1.2rem;
}
.submit-button {
  width: 110px;
  background-repeat: no-repeat;
  height: 35px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;
  bottom: 0;
  background-color: #ef4444;
  color: #fff;
  border-radius: 5px;
  font-size: 1.2rem;
  letter-spacing: 3px;
  font-family: "PixelFont", sans-serif;
  border: none;
}

.submit-button:hover {
  transform: scale(1.03);
  opacity: 0.85;
}

.social-input {
  display: flex;
  flex-direction: column;
  text-align: start;
  margin: 0 15%;
  gap: 10px;
}

.error-message {
  color: red;
  padding: 5px;
}

label {
  color: #000;
  text-transform: uppercase;
  text-align: center;
}

@media (max-width: 1024px) {
  .large-frame {
    width: 88vw;
    height: 810px;
  }
  .manifesto {
    font-size: 1rem;
  }
  .frame-content {
  }
  .manifesto-frame-title {
    top: 10px;
  }
  .medium-frame {
    width: 52vw;
    height: 520px;
  }
}

@media (max-width: 778px) {
  .medium-frame {
    width: 70vw;
    height: 28vh;
    padding-bottom: 10px;
  }
  .xs-small-frame {
    height: 10vh;
    overflow-y: hidden;
    padding: 0px !important;
  }
}
@media (max-width: 667px) {
  .close-icon-small {
    top: -11%;
    left: 4.5%;
    z-index: 1000;
  }
  .aboutus-close {
    top: -4.5% !important;
  }
  .aboutus-title {
    top: -4% !important;
  }
  .about-frame {
    height: 70vh !important;
  }
  .memes-title {
    top: -3.5% !important;
  }
  .memes-close {
    top: -4% !important;
  }
  .memes-frame {
    height: 80vh !important;
  }
  .collabs-title {
    top: -7% !important;
  }
  .frame-title {
    top: -9%;
  }
  .collabs-close {
    top: -8% !important;
  }
  .testing {
    top: -3.8% !important;
  }
  .frame-content {
    padding-bottom: 10px;
  }
  .large-frame {
    height: 80vh;
  }
  .manifesto,
  .medium-text {
    position: absolute;
    top: 1%;
  }
  .manifesto {
    height: 70vh;
    overflow-y: scroll;
    margin-bottom: 10vh;
  }
  .frame-title h1 {
    font-size: 1.3rem;
  }
  .manifesto-frame-title {
    top: -4%;
  }
  .manifesto-frame-title h1 {
    font-size: 1.5rem;
  }
  .medium-text {
    height: 400px;
    overflow: scroll;
  }
  .medium-frame,
  .large-frame,
  .small-frame,
  .x-small-frame,
  .xs-small-frame {
    width: 80vw;
  }
  .frame-content {
    overflow-y: scroll;
  }
  ul li {
    padding: 0.5rem;
    font-size: 1rem;
  }
  .para {
    font-size: 1rem;
  }
  .input-container p {
    font-size: 0.9rem;
  }
  .close-icon-large img {
    width: 27px;
    height: 24px;
  }

  .x-small-frame {
    height: 32vh;
    overflow-y: scroll;
  }
}
