@font-face {
  font-family: "PixelFont";
  src: url("./assets/font/w95fa.woff2") format("woff2");
}

html,
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "PixelFont";
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; /* For smooth scrolling on mobile */
}
/* Main container */
.main-container {
  height: 100%;
  width: 100%;
}

/* Video container positioning */
.layout-container {
  position: relative;
  height: 100vh;

  background-image: url("../src/assets/images/SideColumns.png");
  background-size: 100%;
  background-position: center;
}

/* Background video styling */
.bg-video {
  background: #9f43c8;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  min-height: 100%;
  object-fit: fill;
  object-position: center;
  transform: translate(-50%, -50%);
  z-index: -1;
}
.bg-title {
  background-image: url("../src/assets/images/topColumn.png");
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: top center;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
}

.bg-title > .title {
  transform: scale(0.5);
}

@media (min-width: 1900px) {
  .layout-container {
  }
}
@media (max-width: 1366px) {
  .layout-container {
    background-position: top;
    height: 100%;
    min-height: 100vh;
  }
}

@media (max-width: 1024px) {
  .main-content {
    position: unset !important;
  }

  .bg-title {
    background-size: cover;
    transform: scale(0.9);
  }

  .bg-title > .title {
    margin-top: 0;
    transform: scale(0.4);
  }
}

@media (max-width: 667px) {
  .bg-video::-webkit-media-controls {
    display: none !important;
  }

  .bg-video::-webkit-media-controls-overlay-play-button {
    display: none !important;
  }

  .bg-video::-webkit-media-controls-enclosure {
    display: none !important;
  }

  .bg-video::-webkit-media-controls-panel {
    display: none !important;
  }
}

@media (max-width: 400px) {
  .layout-container {
    /* background-size: contain; */
    /* background-position: top; */
    /* height: 100%;
    min-height: 100vh;
    overflow: hidden; */
  }
}
