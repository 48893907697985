.batList-container {
  width: 100%;
  height: 100%;
  display: flex;
  margin-left: 7%;
  overflow-y: scroll;
}
.form-container {
  display: flex;
  flex-direction: column;
  margin-top: 4%;
  margin-bottom: 2%;
  margin-left: 1%;
}

@media (max-width: 1024px) {
  .batList-container {
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 2rem;
    overflow-y: scroll;
  }
  .form-container {
    margin-top: 0%;
    height: auto !important;
  }
}
.xs-small-frame {
}
