.nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  width: 40%;
}

.nav-item:nth-child(odd) {
  margin-right: auto;
}

.nav-item:nth-child(even) {
  margin-left: auto;
}

.nav-item:hover {
  transform: scale(1.1);
}

.icon {
  width: 100%;
}
.nav-item-text {
  margin-top: 0.2rem;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: "PixelFont";
}

@media (max-width: 768px) {
  .nav-item {
    width: 60%;
  }
  .nav-item-text {
    font-size: 0.9rem;
  }
}
@media (max-width: 425px) {
  .nav-item {
    width: 90%;
  }
}
