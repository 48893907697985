/* Loader styles */
.loader {
  border: 8px solid rgba(255, 255, 255, 0.2); /* Light gray */
  border-top: 8px solid #fff; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
}

/* Animation for loader spin */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Overlay styles */
