.main-content {
  width: 100%;
  height: 100%;
  min-height: 90vh;
  position: relative;
}

.nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 30px auto;
  width: 80%;
}

.bat-image {
  position: absolute;
  bottom: 5%;
  left: 45%;
}

.bat-image img {
  width: 150px;
  transform: rotate(355deg);
}

@media (min-width: 1050px) and (max-width: 1366px) {
  .bat-image {
    bottom: 0%;
  }
}
@media (min-width: 800px) and (max-width: 1024px) {
  .bat-image {
    bottom: 0%;
  }
}
@media (max-width: 768px) {
  .bat-image {
    bottom: 0%;
    left: 36%;
  }

  .bat-image img {
    width: 100px;
    transform: rotate(355deg);
  }
}
@media (min-width: 420px) and (max-width: 433px) {
  .bat-image {
    bottom: 5%;
  }
}
