.login-container {
  height: 100%;
  width: 100%;
}
.login-from-main {
  width: 50%;
  display: flex;
  justify-content: center;
}
.login-from-main img {
  width: 120px;
  height: 60px;
}
.btc-text-wrapper {
  width: 64%;
  margin: 10px auto;
  height: auto;
}
.btc-text {
  width: 100%;
  margin: 0px auto;
}

.login-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

/* Container for the entire form, using the background image */
.login-form-container {
  width: 100%;
  margin: 0 auto;
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .form-wrapper {
    height: 88px !important;
  }
}
.form-wrapper {
  position: relative;
  width: 450px;
  height: 100px;
  background-image: url("../../assets/images/Batprofile1.png");
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

/* Style for the input field */
.login-input {
  border: none;
  outline: none;

  font-size: 18px;
  width: 56%;
  font-family: "Courier New", Courier, monospace;
  text-align: center;

  border-radius: 5px;
  border: 1px solid grey;
  padding: 14px 2px;
  background-color: #ffffff;
  color: grey;
  outline: none;
  box-shadow: none;
  font-size: 16px;
}

.login-input::placeholder {
  color: darkgrey;
  font-weight: bolder;
}

.login-input:focus {
  outline: none;
  box-shadow: none;
}

/* Style for the custom login button */
.login-button {
  border-radius: 8px;
  background-color: #22c55e;
  width: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;

  font-size: 1.3rem;
  letter-spacing: 4px;
  font-family: "PixelFont", sans-serif;
  color: #fff;
}

/* Adding an interactive hover effect */
.login-button:hover {
  transform: scale(1.03);
  opacity: 0.85;
}

/* Ensure the form layout is responsive on smaller screens */

@media (min-width: 415px) and (max-width: 433px) {
  .login-form-container {
    width: 100%;
    transform: scale(0.8);
  }
}
@media (max-width: 433px) {
  .login-from-main {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .login-button {
    width: 70px;
    top: 38%;
    right: 2%;
    font-size: 1.1rem;
    letter-spacing: 2px;
  }
  .login-input::placeholder {
    color: #d1d1d1;
    font-size: 0.7rem;
    padding: 0px;
  }
  .login-input {
  }
}
@media (min-width: 376px) and (max-width: 414px) {
  .login-form-container {
    width: 100%;
    transform: scale(0.8);
  }
  .form-wrapper {
    height: 90px;
  }
}

@media (max-width: 375px) {
  .login-form-container {
    width: 100%;
    transform: scale(0.8);
  }
  .form-wrapper {
    height: 83px;
  }
}
@media (max-width: 320px) {
  .form-wrapper {
    height: 70px;
  }
  .login-button {
    width: 92px;
    height: 35px;
    top: 23px;
  }
}
