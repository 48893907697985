.memes-container {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: center;
  margin: 3% auto;
}

.memes-content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 32%;
  position: relative;
  top: 32%;
}
.memes-content img {
  width: 180px;
  height: 120px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 425px) {
  .memes-content img {
    width: 140px;
    height: 100px;
  }
}

@media (max-width: 768px) {
  .memes-content {
    padding: 10px 0px;
    top: 35%;
  }
}
