/* Loader styles */
.loader {
  border: 8px solid rgba(255, 255, 255, 0.2); /* Light gray */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

/* Animation for loader spin */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Overlay styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(
    0,
    0,
    0,
    0.3
  ); /* Transparent dark overlay (30% opacity) */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensures the loader is on top of other elements */
  backdrop-filter: blur(5px); /* Blur effect on the background */
}
