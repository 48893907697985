body {
  font-family: "PixelatedFont", sans-serif;
  background-color: #0c1445;
  color: white;
  margin: 0;
  padding: 0;
  text-align: center;
}

.not-found {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.not-found-text h1 {
  font-size: 6rem;
  color: #ff4040;
}

.not-found-text p {
  font-size: 1.5rem;
  margin: 40px 0;
  color: #fff;
}

.not-found-text a {
  color: yellow;
  text-decoration: none;
  border: 2px solid yellow;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.not-found-text a:hover {
  background-color: yellow;
  color: black;
}

.bat-flying img {
  width: 200px;
  margin-top: 5px;
}
